// AppsLogo.tsx

import React from "react";

export default function AppsLogo() {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="Logo">
            <path d="M7 2C6.46957 2 5.96086 2.21071 5.58579 2.58579C5.21071 2.96086 5 3.46957 5 4V16C5 16.5304 
                5.21071 17.0391 5.58579 17.4142C5.96086 17.7893 6.46957 18 7 18H13C13.5304 18 14.0391 17.7893 14.4142 
                17.4142C14.7893 17.0391 15 16.5304 15 16V4C15 3.46957 14.7893 2.96086 14.4142 2.58579C14.0391 2.21071 
                13.5304 2 13 2H7ZM2 6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4V16C3.46957 
                16 2.96086 15.7893 2.58579 15.4142C2.21071 15.0391 2 14.5304 2 14V6ZM16 16V4C16.5304 4 17.0391 4.21071 
                17.4142 4.58579C17.7893 4.96086 18 5.46957 18 6V14C18 14.5304 17.7893 15.0391 17.4142 15.4142C17.0391 
                15.7893 16.5304 16 16 16Z"/>
        </svg>
    )
}
