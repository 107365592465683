import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { useLangContext } from '../../contexts/LangContext';
import { usePageContext } from '../../contexts/PageContext';

import Button from './Button';
import ALLLogo from '../Utilities/ALLLogo';
import AdsLogo from '../Utilities/AdsLogo';
import LangLogo from '../Utilities/LangLogo';
import AppsLogo from '../Utilities/AppsLogo';
import TuneLogo from '../Utilities/TuneLogo';

import '../../styles/NavBar.css';
import '../../styles/color.css';
import '../../styles/size.css';

import data from '../../data.json';
import Menu from './Menu';


export default function NavBar() {
    const { lang, setLang } = useLangContext();
    const [hoveredButton, setHoveredButton] = useState<string | null>(null);
    const [ isMenuHovered, setIsMenuHovered ] = useState(false);
    const currentPage = usePageContext().page;

    const isLargeScreen = useMediaQuery({ query: '(min-width : 800px), (orientation : landscape)' });

    function changeLang() {
        setLang(lang === 'en' ? 'fr' : 'en');
    }

    const handleMouseEnter = (buttonName: string) => {
        setHoveredButton(buttonName);
    };

    const handleMouseLeave = () => {
        if (!isMenuHovered) {
            setHoveredButton(null);
        }
    };

    const handleMenuMouseEnter = () => {
        setIsMenuHovered(true);
    };

    const handleMenuMouseLeave = () => {
        setIsMenuHovered(false);
        setHoveredButton(null);
    };

    return (
        <nav>
            {!isLargeScreen && hoveredButton === 'Apps' &&
                <Menu handleMenuEnter={handleMenuMouseEnter} handleMenuLeave={handleMenuMouseLeave}/>
            }

            <div className='buttons'>
                <Button to='/About' page='About' Logo={ALLLogo} active_class='greyed' label={data['About'].title[lang as keyof typeof data['Language']]}/>

                <div
                    className={'nav-button-container apps'}
                    onMouseEnter={() => handleMouseEnter('Apps')}
                    onMouseLeave={handleMouseLeave}
                >   
                    <div className={`nav-button ${currentPage === 'Tune' ? 'purpled' : ''}`}>
                        <div className='logo'>
                            {currentPage === 'Tune' ? <TuneLogo/> : <AppsLogo/>}
                        </div>

                        <span>
                            {currentPage === 'Tune' ? 'Tune' : 'Apps'}
                        </span>
                    </div>
                </div>

                <Button to='/Ads'  page='Ads' Logo={AdsLogo} active_class='reded'/>

                <Button Logo={LangLogo} onClick={changeLang} label={data["Language"][lang as keyof typeof data['Language']]}/>
            </div>

            {isLargeScreen && hoveredButton === 'Apps' &&
                <Menu handleMenuEnter={handleMenuMouseEnter} handleMenuLeave={handleMenuMouseLeave}/>
            }
        </nav>
    );
}
