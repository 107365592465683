import React from 'react';
import { LogoProps } from '../Utilities/Props';
import { useNavigate } from 'react-router-dom';

interface MenuLineProps {
    Logo: React.FC<LogoProps>;
    title: string;
    to?: string;
    onClick?: () => void;
}
     

export default function MenuLine({ Logo, title, to, onClick }: MenuLineProps) {
    const navigate = useNavigate();

    function handleClick() {
        if (onClick) {
            onClick();
        }

        if (to) {
            navigate(to);
        }
    }

    return (
        <div className="menu-line" onClick={handleClick}>
            <div className='logo-container'>
                <Logo/>
            </div>
            <span>
                {title}
            </span>
        </div>
    )
}
