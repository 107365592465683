import React from "react";
import MenuLine from "./MenuLine";
import TuneLogo from "../Utilities/TuneLogo";
import HarmateLogo from "../Utilities/HarmateLogo";


interface MenuProps {
    handleMenuEnter: () => void;
    handleMenuLeave: () => void;
}


export default function Menu({ handleMenuEnter, handleMenuLeave }: MenuProps) {

    function handleHarmateClick() {
        window.location.href = 'https://www.harmate.com';
    }

    return (
        <div className="menu" onMouseEnter={handleMenuEnter} onMouseLeave={handleMenuLeave}>
            <MenuLine Logo={TuneLogo} title={'Tune'} to='Tune'/>
            <MenuLine Logo={HarmateLogo} title={'Harmate'} onClick={handleHarmateClick}/>
        </div>
    )
}